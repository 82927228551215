import { makeStyles } from '@material-ui/core';

export const useInputStyles = makeStyles({
  input: {
    width: '100%',
    '& > div, & > div:hover': {
      backgroundColor: 'transparent'
    }
  },
  input_md: {
    width: '100%',
    maxWidth: '300px',
    '& > div, & > div:hover': {
      backgroundColor: 'transparent'
    }
  },
  textarea: {
    width: '100%',
    '& > div, & > div:hover': {
      backgroundColor: 'transparent'
    }
  },
  droparea: {
    width: '50%'
  },
  list: {
    minWidth: '100px',
    '& > div, & > div:hover, .MuiFilledInput-root, .MuiFilledInput-root.Mui-focused, .MuiSelect-select:focus': {
      backgroundColor: 'transparent'
    }
  },
  date: {
    width: '220px',
    '& > div, & > div:hover': {
      backgroundColor: 'transparent'
    }
  },
  number: {
    width: '220px',
    '& > div, & > div:hover': {
      backgroundColor: 'transparent'
    }
  },
  dropdown: {
    width: '50%',
    '& > div, & > div:hover': {
      backgroundColor: 'transparent'
    }
  },
  update: {
    width: '100%',
    padding: '10px',
    backgroundColor: 'rgb(242, 249, 255)',
    boxSizing: 'border-box'
  }
});