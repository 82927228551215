import React from 'react';
import {
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  FileField,
  Datagrid,
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  EditButton,
  CloneButton
} from 'react-admin';
import { Link } from 'react-router-dom';

const DocumentActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

const DocumentTitle = ({ record }) => <span>{record ? record.name : 'Dokument'}</span>

const UserField = ({ record = {} }) => {
  return (
    <Link to={`/users/${record.id}/show`}>
      {record.Firstname
        ? `${record.Firstname} ${record.Lastname}`
        : record.username
          ? record.username
          : record.email
      }
    </Link>
  )
}

export const DocumentShow = props => (
  <Show {...props} title={<DocumentTitle />} actions={<DocumentActions />}>
    <SimpleShowLayout>
      <TextField source="name" label="Name" />
      <TextField source="category" label="Kategorie" />
      <DateField source="created_at" label="Erstellt am" />
      <FileField source="file.fullUrl" title="file.name" target="_blank" label="Datei" />
      <ArrayField source="groups" label="Gruppen">
        <SingleFieldList><ChipField source="DisplayName" /></SingleFieldList>
      </ArrayField>
      <ArrayField label="Users" source="users">
        <Datagrid>
          <UserField source="id" label="Name" />
          <TextField source="email" label="E-Mail" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);