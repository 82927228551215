import React from 'react';
import {
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Show,
  SimpleShowLayout,
  ImageField,
  TopToolbar,
  ListButton,
  EditButton,
  CloneButton
} from 'react-admin';

const EventActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

const EventTitle = ({ record }) => <span>{record ? record.name : 'Veranstaltung'}</span>

export const EventShow = props => {
  return (
    <Show {...props} title={<EventTitle />} actions={<EventActions />}>
      <SimpleShowLayout>
        <TextField source="name" label="Name" />
        <TextField source="Description" label="Beschreibung" />
        <DateField source="start" showTime label="Start" />
        <DateField source="finish" showTime label="Ende" />
        <ImageField source="teaser.fullUrl" title="teaser.name" label="Teaser Bild" />
        <ArrayField source="groups">
          <SingleFieldList><ChipField source="DisplayName" /></SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
};