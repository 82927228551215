export const holidayType = {
  holiday: 'Erholungsurlaub',
  workfree: 'Arbeitsbefreiung nach §29 TV-L',
  advanced: 'Fortbildung',
  education: 'Bildungsurlaub'
}

export const holidayTypeList = [
  { id: 'holiday', name: 'Erholungsurlaub' },
  { id: 'workfree', name: 'Arbeitsbefreiung nach §29 TV-L' },
  { id: 'advanced', name: 'Fortbildung' },
  { id: 'education', name: 'Bildungsurlaub' }
]