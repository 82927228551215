import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  CloneButton,
  Filter,
  TextInput,
  BooleanInput,
  SelectArrayInput,
  SimpleList,
  TopToolbar,
  CreateButton
} from 'react-admin';
import { ImportButton } from 'react-admin-import-csv';
import { useMediaQuery } from '@material-ui/core';
import useGroups from '../resourceHooks/useGroups';
import { useListStyles, useDatagridStyles, useInputStyles } from '../../styles/index';

const UserActions = (props) => {
  return (
    <TopToolbar className={props.className}>
      <CreateButton basePath={props.basePath} />
      <ImportButton {...props} />
    </TopToolbar>
  );
};

const UserFilter = (props) => {
  const groups = useGroups();
  const classes = useInputStyles();
  return (
    <Filter {...props}>
      <TextInput className={classes.input} label="Vorname" source="Firstname_contains" defaultValue="" alwaysOn />
      <TextInput className={classes.input} label="Nachname" source="Lastname_contains" defaultValue="" alwaysOn />
      <SelectArrayInput className={classes.list} label="Groups" source="groups_in" choices={groups} optionText="DisplayName" />
      <BooleanInput source="blocked" label="Blockiert" />
    </Filter>
  )
};

const NameField = ({ record = {} }) => (
  record.Firstname
    ? `${record.Firstname} ${record.Lastname}`
    : record.username
      ? record.username
      : record.email
)

export const UserList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const listClasses = useListStyles();
  const datagridClasses = useDatagridStyles();

  return (
    <List
      {...props}
      title="Benutzer"
      bulkActionButtons={false}
      filters={<UserFilter />}
      actions={<UserActions />}
      classes={{ ...listClasses }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => < NameField record={record} />}
          secondaryText={record => record.email}
          linkType="show"
        />
      ) : (
          <Datagrid rowClick="show" classes={{ ...datagridClasses }}>
            <TextField source="Firstname" label="Vorname" />
            <TextField source="Lastname" label="Nachname" />
            <TextField source="email" label="E-Mail" />
            <ArrayField source="groups" label="Gruppen">
              <SingleFieldList><ChipField source="DisplayName" /></SingleFieldList>
            </ArrayField>
            {/* <BooleanField source="confirmed" label="Bestätigt" /> */}
            <BooleanField source="blocked" label="Blockiert" />
            <EditButton />
            <CloneButton />
          </Datagrid>
        )}

    </List>
  );
}