import { makeStyles } from '@material-ui/core';

export const useListStyles = makeStyles({
  root: {
    
  },
  main: {
    
  },
  content: {
    
  },
  actions: {
    
  },
  noResults: {

  },
  bulkActionsDisplayed: {

  }
});