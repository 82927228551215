import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateTimeInput,
  FileField,
  FileInput,
  BooleanInput,
  SelectArrayInput,
  TopToolbar,
  ListButton,
  ShowButton,
  CloneButton
} from 'react-admin';
import useGroups from '../resourceHooks/useGroups';
import { useInputStyles } from '../../styles/index';

const EventActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

const EventTitle = ({ record }) => <span>{record ? record.name : 'Veranstaltung'}</span>

export const EventEdit = props => {
  const groups = useGroups();
  const classes = useInputStyles();

  return (
    <Edit {...props} title={<EventTitle />} actions={<EventActions />}>
      <SimpleForm redirect="show">
        <TextInput source="name" label="Name" className={classes.input} />
        <TextInput source="Description" label="Beschreibung" multiline={true} className={classes.textarea} />
        <DateTimeInput source="start" label="Start" className={classes.date} />
        <DateTimeInput source="finish" label="Ende" className={classes.date} />
        <FileInput source="teaser" label="Teaser Bild" className={classes.droparea}>
          <FileField source="fullUrl" title="name" target="_blank" />
        </FileInput>
        <SelectArrayInput label="Gruppen" source="groupsIds" choices={groups} optionText="DisplayName" className={classes.list} />
        <BooleanInput source="updateNotify" label="Benachrichtigung über Änderungen senden" className={classes.update} />
      </SimpleForm>
    </Edit>
  )
}