import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import {
  Card,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@material-ui/core';
import { useDashboardStyles } from '../../../styles/DashboardStyles';
import CardIcon from './CardIcon';
import EventIcon from '@material-ui/icons/Event';

const zeroPad = (value, length = 2) => {
  return `${value}`.padStart(length, '0');
};

const NewEvents = () => {
  const [events, setEvents] = useState([]);
  const dataProvider = useDataProvider();
  const classes = useDashboardStyles();

  const fetchEvents = useCallback(async () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const start = `${year}-${zeroPad(month)}-01T00:00:00Z`;
    const finish = `${year}-${zeroPad(month)}-${zeroPad(new Date(year, month, 0).getDate())}T23:59:59Z`;

    const { data: events } = await dataProvider.getList('events', {
      filter: { start_gte: start, finish_lte: finish },
      sort: { field: 'start', order: 'ASC' },
      pagination: { page: 1, perPage: 100 }
    });
    setEvents(events);

  }, [dataProvider])

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  return (
    <div className={classes.main}>
      <CardIcon Icon={EventIcon} bgColor="#f44336" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          Aktuelle Veranstaltungen
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          className={classes.value}
        >
          <Link to='/events' className={classes.titleLink}>
            {events.length}
          </Link>
        </Typography>
        <Divider />
        {events && (
          <List>
            {events.map((record) => (
              <ListItem
                key={record.id}
                button
                component={Link}
                to={`/events/${record.id}/show`}
                alignItems="flex-start"
              >
                <ListItemAvatar>
                  {record.teaser ? (
                    <Avatar
                      src={`${record.teaser.fullUrl}`}
                      className={classes.avatar}
                    />
                  ) : (
                      <Avatar><EventIcon /></Avatar>
                    )}
                </ListItemAvatar>

                <ListItemText
                  primary={record.name}
                  secondary={record.Description}
                  className={classes.listItemText}
                  style={{ paddingRight: 0 }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Card>
    </div>
  )
};

export default NewEvents;