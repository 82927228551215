import React from 'react';
import {
  SimpleForm,
  TextInput,
  Create,
  BooleanInput,
  SelectArrayInput,
  TopToolbar,
  ListButton
} from 'react-admin';
import useGroups from '../resourceHooks/useGroups';
import { useInputStyles } from '../../styles/index';
import { validateCreateUser } from './userFormValidation';

const UserActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const UserCreate = props => {
  const groups = useGroups();
  const classes = useInputStyles();

  return (
    <Create {...props} title="Neues Benutzerkonto" actions={<UserActions />}>
      <SimpleForm redirect="show" validate={validateCreateUser}>
        <TextInput source="username" label="Nutzername" required className={classes.input_md} />
        <TextInput source="password" label="Passwort" type="password" required className={classes.input_md} />
        <TextInput source="DocumentPin" label="PIN" type="password" className={classes.input_md} />
        <TextInput source="Firstname" label="Vorname" className={classes.input_md} />
        <TextInput source="Lastname" label="Nachname" className={classes.input_md} />
        <TextInput label="E-mail" source="email" type="email" className={classes.input_md} />
        <SelectArrayInput label="Gruppen" source="groupsIds" choices={groups} optionText="DisplayName" className={classes.list} />
        <BooleanInput source="blocked" label="Blockiert" />
      </SimpleForm>
    </Create >
  )
}