import { makeStyles } from '@material-ui/core';

export const useFormStyles = makeStyles(
  (theme) => ({
    formWrapper: {
      maxWidth: 400,
      margin: 'auto'
    },
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    submit: {
      padding: '0 1em 1em 1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    link: {
      display: 'block',
      margin: '0.75em 0',
      textDecoration: 'none',
      color: '#3f51b5',
    },
    formError: {
      color: 'red',
      marginTop: '0.5rem'
    },
    errorIcon: {
      display: 'inline-block',
      backgroundColor: 'red',
      color: 'white',
      height: '20px',
      width: '20px',
      borderRadius: '50%',
      marginRight: '5px',
      textAlign: 'center',
      lineHeight: '20px'
    }
  }),
  { name: 'RaLoginForm' }
);