import React from 'react';
import { Field, Form } from 'react-final-form';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import { useNotify, useSafeSetState } from 'react-admin';
import Input from './Input';
import { useFormStyles } from '../../styles/FormStyles';
import { apiUrl, httpClient } from '../../providers/dataProvider';

const ForgotPassword = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const notify = useNotify();
  const classes = useFormStyles();

  const validate = (values) => {
    const errors = { email: undefined };
    if (!values.email) {
      errors.email = 'E-Mail is required';
    }
    return errors;
  };

  const submit = values => {
    setLoading(true);
    return httpClient(`${apiUrl}/auth/forgot-password`, {
      method: 'POST',
      body: JSON.stringify({
        email: values.email,
        url: `${apiUrl}/admin/plugins/users-permissions/auth/reset-password`
      })
    }).then(({ json }) => {
      console.log(json);
      setLoading(false);
      notify('E-Mail zum Zurücksetzen des Passwortes gesendet', 'success');
    }).catch(error => {
      setLoading(false);
      notify(typeof error === 'string'
        ? error
        : error.message,
        'warning');
    });
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.formWrapper}>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="email"
                name="email"
                component={Input}
                label="E-Mail"
                disabled={loading}
              />
            </div>
          </div>
          <Typography className={classes.submit}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              Passwort vergessen
            </Button>
          </Typography>
        </form>
      )}
    />
  );
};

export default ForgotPassword;