import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  AutocompleteArrayInput,
  TopToolbar,
  ListButton,
  ShowButton,
  CloneButton
} from 'react-admin';
import { useInputStyles } from '../../styles/index';
import useUsers from '../resourceHooks/useUsers';

const GroupActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

const GroupTitle = ({ record }) => <span>{record ? record.DisplayName : 'Gruppe bearbeiten'}</span>

const optionRenderer = choice => choice ? `${choice.Firstname} ${choice.Lastname}` : '';

export const GroupEdit = props => {
  const classes = useInputStyles();
  const users = useUsers();
  return (
    <Edit {...props} title={<GroupTitle />} actions={<GroupActions />}>
      <SimpleForm redirect="show">
        <TextInput source="DisplayName" label="Name" className={classes.input_md} />
        <BooleanInput source="isGeneral" label="Allgemein" />
        <AutocompleteArrayInput source="usersIds" label="Mitglieder" choices={users} optionText={optionRenderer} className={classes.input} />
      </SimpleForm>
    </Edit>
  )
}