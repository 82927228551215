import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

export const apiUrl = 'https://intranet-api.diakoniestation-leer.de';
export const httpClient = fetchUtils.fetchJson;

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      _sort: `${field}:${order.toLowerCase()}`,
      _limit: perPage,
      _start: (page - 1) * perPage,
      ...params.filter
    };
    const token = localStorage.getItem('jwt');
    const options = {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      })
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url, options).then(({ headers, json }) => {
      return {
        data: json.data,
        total: json.count,
      }
    });
  },

  getAll: (resource, params) => {
    const token = localStorage.getItem('jwt');
    const options = {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      })
    }
    const url = `${apiUrl}/${resource}`;

    return httpClient(url, options).then(({ json }) => {
      return {
        data: json.data,
      }
    });
  },

  getOne: (resource, params) => {
    const token = localStorage.getItem('jwt');
    const options = {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      })
    }
    return httpClient(`${apiUrl}/${resource}/${params.id}`, options).then(({ json }) => ({
      data: json,
    }))
  },

  getMany: (resource, params) => {
    console.log(resource, params);
    const token = localStorage.getItem('jwt');
    const options = {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      })
    }
    const url = `${apiUrl}/${resource}/${params.ids}`;

    return httpClient(url, options).then(({ json }) => {
      return {
        data: [json]
      }
    });
  },

  getManyReference: (resource, params) => {
    console.log('get many reference', resource, params);
  },

  update: async (resource, params) => {
    const token = localStorage.getItem('jwt');

    return await httpClient(`${apiUrl}/${resource}/${params.id}`, {
      headers: new Headers({
        Authorization: `Bearer ${token}`
      }),
      method: 'PUT',
      body: getFormdata(params.data)
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) => {
    const token = localStorage.getItem('jwt');

    return httpClient(`${apiUrl}/${resource}`, {
      headers: new Headers({
        Authorization: `Bearer ${token}`
      }),
      method: 'POST',
      body: getFormdata(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }))
  },

  delete: (resource, params) => {
    const token = localStorage.getItem('jwt');

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }),
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },

  deleteMany: (resource, params) => {
    const token = localStorage.getItem('jwt');
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }),
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  }
};

const getFormdata = (data) => {
  let formdata = JSON.stringify(data);

  if (data.attachment || data.teaser || data.file) {
    formdata = new FormData();
    formdata.append('data', JSON.stringify(data));
    if (data.attachment) {
      if (Array.isArray(data.attachment)) {
        data.attachment.forEach((item, index) => {
          formdata.append('files[attachment]', item.rawFile);
        });
      } else {
        formdata.append('files[attachment]', data.attachment.rawFile)
      };
    };
    if (data.teaser) {
      formdata.append('files[teaser]', data.teaser.rawFile);
    };
    if (data.file) {
      formdata.append('files[file]', data.file.rawFile);
    };
  };

  return formdata;
}