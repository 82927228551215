import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  Filter,
  TextInput,
  SimpleList,
  CloneButton
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useListStyles, useDatagridStyles, useInputStyles } from '../../styles/index';

const GroupFilter = (props) => {
  const classes = useInputStyles();
  return (
    <Filter {...props}>
      <TextInput className={classes.input} label="Name" source="DisplayName_contains" defaultValue="" alwaysOn />
    </Filter>
  )
};

const UserCountField = ({ record = {} }) => {
  console.log(record);
  return (
    <span>{record.users.length}</span>
  )
};

export const GroupList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const listClasses = useListStyles();
  const datagridClasses = useDatagridStyles();

  return (
    <List
      {...props}
      title="Gruppen"
      exporter={false}
      bulkActionButtons={false}
      filters={<GroupFilter />}
      classes={{ ...listClasses }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => record.DisplayName}
          linkType="show"
        />
      ) : (
          <Datagrid rowClick="show" classes={{ ...datagridClasses }}>
            <TextField source="DisplayName" label="Name" />
            <BooleanField source="isGeneral" label="Allgemein" />
            <UserCountField source="users" label="Benutzeranzahl" />
            <EditButton />
            <CloneButton />
          </Datagrid>
        )}
    </List>
  )
}