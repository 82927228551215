import React from 'react';
import NewEvents from './Partials/NewEvents';
import NewMessages from './Partials/NewMessages';
import NewVacations from './Partials/NewVacations';
import { useMediaQuery } from '@material-ui/core';
import { useAuthState } from 'react-admin';

const styles = {
    flex: {
        display: 'flex'
    },
    flexColumn: {
        flexDirection: 'column'
    },
    flexItem: {
        flex: 1,
        margin: '2rem 0.5rem'
    }
}

const Dashboard = () => {
    const isSmall = useMediaQuery(theme =>
        theme.breakpoints.down('md')
    );
    const { loading, authenticated } = useAuthState();

    return (
        <div style={{
            ...styles.flex,
            ...(isSmall && styles.flexColumn)
        }}>
            {!loading && authenticated && (
                <>
                    <div style={styles.flexItem}>
                        <NewEvents />
                    </div>
                    <div style={styles.flexItem}>
                        <NewMessages />
                    </div>
                    <div style={styles.flexItem}>
                        <NewVacations />
                    </div>
                </>
            )}
        </div>
    )
};

export default Dashboard;