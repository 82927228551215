import React from 'react';
import {
  SimpleForm,
  TextInput,
  Create,
  BooleanInput,
  AutocompleteArrayInput,
  TopToolbar,
  ListButton
} from 'react-admin';
import { useInputStyles } from '../../styles/index';
import useUsers from '../resourceHooks/useUsers';

const GroupActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

const optionRenderer = choice => {
  console.log(choice);
  return choice ? `${choice.Firstname} ${choice.Lastname}` : ''
};

export const GroupCreate = props => {
  const classes = useInputStyles();
  const users = useUsers();
  return (
    <Create {...props} title="Neue Gruppe" actions={<GroupActions />}>
      <SimpleForm redirect="show">
        <TextInput source="DisplayName" label="Name" className={classes.input_md} />
        <BooleanInput source="isGeneral" label="Allgemein" />
        <AutocompleteArrayInput source="usersIds" label="Mitglieder" choices={users} optionText={optionRenderer} className={classes.input} />
      </SimpleForm>
    </Create>
  )
}