import React, { useState } from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import { apiUrl, httpClient } from '../../providers/dataProvider';

function generatePassword(length = 8) {
  var password = '', character;
  while (length > password.length) {
    if (password.indexOf(character = String.fromCharCode(Math.floor(Math.random() * 94) + 33), Math.floor(password.length / 94) * 94) < 0) {
      password += character;
    }
  }
  return password;
}

const SetNewPassword = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState('');

  const handleClick = () => {
    setShowDialog(true);
  }

  const handleCloseClick = () => {
    setPassword('');
    setShowDialog(false);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleGenerateClick = () => {
    const newPassword = generatePassword();
    setPassword(newPassword);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false);
    const token = localStorage.getItem('jwt');
    return httpClient(`${apiUrl}/updatePassword`, {
      headers: new Headers({
        Authorization: `Bearer ${token}`
      }),
      method: 'POST',
      body: JSON.stringify({
        id: record.id,
        password: password
      })
    }).then(({ json }) => {
      handleCloseClick();
    }).catch(error => setError('Das Passwort wurde nicht gespeichert'))
  };

  return (
    <>
      <Button variant="contained" color='primary' onClick={handleClick}>Neues Passwort</Button>
      <Dialog
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Neues Password erstellen"
      >
        <DialogTitle>Neues Password</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              id="password"
              label="Passwort"
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              value={password}
              onChange={handlePasswordChange}
            />
            <Button color='default' type="button" onClick={handleGenerateClick}>Generieren</Button>
            {error && (
              <Typography color="error">
                {error}
              </Typography>
            )}

          </DialogContent>
          <DialogActions>
            <Button variant="contained" color='primary' type="submit">Speichern</Button>
            <Button label="ra.action.cancel" onClick={handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default SetNewPassword;