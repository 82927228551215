import React from 'react';
import {
  SimpleForm,
  TextInput,
  FileField,
  FileInput,
  Create,
  SelectArrayInput,
  AutocompleteArrayInput,
  TopToolbar,
  ListButton
} from 'react-admin';
import useGroups from '../resourceHooks/useGroups';
import useUsers from '../resourceHooks/useUsers';
import { useInputStyles } from '../../styles/index';

const optionRenderer = choice => choice ? `${choice.Firstname} ${choice.Lastname}` : '';

const DocumentActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const DocumentCreate = props => {
  const groups = useGroups();
  const users = useUsers();
  const classes = useInputStyles();

  return (
    <Create {...props} title="Neues Dokument" actions={<DocumentActions />} >
      <SimpleForm redirect="show">
        <TextInput source="name" label="Name" className={classes.input} />
        <TextInput source="category" label="Kategorie" className={classes.input_md} />
        <FileInput source="file" label="Datei" className={classes.droparea}>
          <FileField source="url" title="name" target="_blank" />
        </FileInput>
        <SelectArrayInput label="Gruppen" source="groupsIds" choices={groups} optionText="DisplayName" className={classes.list} />
        <AutocompleteArrayInput source="usersIds" label="Users" choices={users} optionText={optionRenderer} className={classes.input} />
      </SimpleForm>
    </Create >
  )
}