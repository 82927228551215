import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

const useGroups = () => {
  const [groups, setGroups] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getAll('groups')
      .then((response) => {
        if (response.data && response.data.length > 0) setGroups(response.data);  
      })
      .catch((e) => {
        console.error(e);
      })
  }, [ dataProvider ]);

  return groups;
};

export default useGroups;