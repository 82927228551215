import { apiUrl } from './dataProvider';

const authProvider = {
  login: async ({ username, password }) => {
    const request = new Request(apiUrl + '/auth/local', {
      method: 'POST',
      body: JSON.stringify({ identifier: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return await fetch(request)
      .then(async (response) => {
        if (response.status < 200 || response.status >= 300) {
          const errorJson = await response.json();
          return Promise.reject(errorJson);
        }
        return response.json();
      })
      .then(async (result) => {
        if (result.user.role.name !== 'Agency-Admin') return Promise.reject({ error: 'Sie haben keine Berechtigung für den Zugriff' });
        await localStorage.setItem('jwt', result.jwt);
      });
  },
  logout: async () => {
    await localStorage.removeItem('jwt');
    return Promise.resolve();
  },
  checkAuth: async () => {
    return await localStorage.getItem('jwt')
      ? Promise.resolve()
      : Promise.reject()
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('jwt');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve()
};

export default authProvider;