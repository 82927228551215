import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import {
  Card,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { useDashboardStyles } from '../../../styles/DashboardStyles';
import CardIcon from './CardIcon';
import MessageIcon from '@material-ui/icons/Email';

const NewMessages = () => {
  const [messages, setMessages] = useState([]);
  const dataProvider = useDataProvider();
  const classes = useDashboardStyles();

  const fetchMessages = useCallback(async () => {
    const { data: messages } = await dataProvider.getList('messages', {
      filter: { published_null: true },
      sort: { field: 'created_at', order: 'DESC' },
      pagination: { page: 1, perPage: 100 }
    });
    setMessages(messages);

  }, [dataProvider])

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  return (
    <div className={classes.main}>
      <CardIcon Icon={MessageIcon} bgColor="#31708f" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          Neue Meldungen
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          className={classes.value}
        >
          <Link to='/messages' className={classes.titleLink}>
            {messages.length}
          </Link>
        </Typography>
        <Divider />
        {messages && (
          <List>
            {messages.map((record) => (
              <ListItem
                key={record.id}
                button
                component={Link}
                to={`/messages/${record.id}/show`}
                alignItems="flex-start"
              >
                <ListItemText
                  primary={record.subject}
                  secondary={record.Body}
                  className={classes.listItemText}
                  style={{ paddingRight: 0 }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Card>
    </div>
  )
};

export default NewMessages;