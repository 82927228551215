import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  CloneButton,
  TextInput,
  SelectArrayInput,
  SimpleList,
  Filter
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import useGroups from '../resourceHooks/useGroups';
import { useListStyles, useDatagridStyles, useInputStyles } from '../../styles/index';

const DocumentFilter = (props) => {
  const groups = useGroups();
  const classes = useInputStyles();
  return (
    <Filter {...props}>
      <TextInput className={classes.input} label="Name" source="name_contains" defaultValue="" alwaysOn />
      <TextInput className={classes.input} label="Kategorie" source="category_contains" defaultValue="" alwaysOn />
      <SelectArrayInput className={classes.list} label="Groups" source="groups_in" choices={groups} optionText="DisplayName" alwaysOn />
    </Filter>
  )
};

const UserCountField = ({ record = {} }) => {
  return (
    <span>{record.users.length}</span>
  )
};

export const DocumentList = (props) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const listClasses = useListStyles();
  const datagridClasses = useDatagridStyles();
  return (
    <List
      {...props}
      title="Dokumente"
      exporter={false}
      bulkActionButtons={false}
      filters={<DocumentFilter />}
      classes={{ ...listClasses }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => record.category}
          tertiaryText={record => <DateField source="created_at" record={record} />}
          linkType="show"
        />
      ) : (
          <Datagrid rowClick="show" classes={{ ...datagridClasses }}>
            <TextField source="name" label="Name" />
            <TextField source="category" label="Kategorie" />
            <DateField source="created_at" label="Erstellt am" />
            <ArrayField source="groups" sortable={false}>
              <SingleFieldList><ChipField source="DisplayName" key="id" /></SingleFieldList>
            </ArrayField>
            <UserCountField source="users" label="Benutzeranzahl" />
            <EditButton />
            <CloneButton />
          </Datagrid>
        )}
    </List>
  )
}