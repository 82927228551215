import React from 'react';
import {
  List,
  Datagrid,
  DateField,
  EditButton,
  CloneButton,
  NumberField,
  BooleanField,
  SimpleList,
  AutocompleteInput,
  Filter
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import useUsers from '../resourceHooks/useUsers';
import { useListStyles, useDatagridStyles, useInputStyles } from '../../styles/index';
import { holidayType } from './data';

const optionRenderer = choice => choice.id ? `${choice.Firstname} ${choice.Lastname}` : '';

const VacationFilter = (props) => {
  const users = useUsers();
  const classes = useInputStyles();
  return (
    <Filter {...props}>
      <AutocompleteInput className={classes.list} label="Benutzer" source="user_eq" choices={users} optionText={optionRenderer} alwaysOn />
    </Filter>
  )
};

const FullNameField = ({ record = {} }) => {
  return (
    record.name
      ? record.name
      : record.user
        ? <span>{record.user.Firstname} {record.user.Lastname}</span>
        : ''
  )
}

const TypeField = ({ record = {} }) => <span>{holidayType[record.type]}</span>

export const VacationList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const listClasses = useListStyles();
  const datagridClasses = useDatagridStyles();
  return (
    <List
      {...props}
      title="Urlaubsanträge"
      exporter={false}
      bulkActionButtons={false}
      filters={<VacationFilter />}
      classes={{ ...listClasses }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => <FullNameField record={record} />}
          secondaryText={record => (
            <>
              <DateField source="start" showTime record={record} />
              &nbsp;-&nbsp;
              <DateField source="finish" showTime record={record} />
            </>
          )}
          tertiaryText={record => <TypeField source="type" record={record} />}
          linkType="show"
        />
      ) : (
          <Datagrid rowClick="show" classes={{ ...datagridClasses }}>
            <FullNameField source="name" label="Name" />
            <TypeField source="type" label="Art der Abwesenheit" />
            <DateField source="start" label="Von" />
            <DateField source="finish" label="Bis" />
            <NumberField source="count" label="Anzahl der Urlaubstage" />
            <BooleanField source="approved" label="Akzeptiert" />
            <EditButton />
            <CloneButton />
          </Datagrid>
        )}
    </List>
  );
}
