import React from 'react';
import {
  TextField,
  BooleanField,
  ArrayField,
  Show,
  SimpleShowLayout,
  Datagrid,
  TopToolbar,
  ListButton,
  EditButton,
  CloneButton
} from 'react-admin';
import { Link } from 'react-router-dom'

const GroupActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

const GroupTitle = ({ record }) => <span>{record ? record.DIsplayName : 'Gruppe'}</span>

const UserField = ({ record = {} }) => {
  return (
    <Link to={`/users/${record.id}/show`}>
      {record.Firstname
        ? `${record.Firstname} ${record.Lastname}`
        : record.username
          ? record.username
          : record.email
      }
    </Link>
  )
}

export const GroupShow = props => (
  <Show {...props} title={<GroupTitle />} actions={<GroupActions />}>
    <SimpleShowLayout>
      <TextField source="DisplayName" label="Name" />
      <BooleanField source="isGeneral" label="Allgemein" />
      <ArrayField label="Mitglieder" source="users">
        <Datagrid>
          <UserField source="id" label="Name" />
          <TextField source="email" label="E-Mail" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);