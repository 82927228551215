import React from 'react';
import {
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  FileField,
  ImageField,
  Datagrid,
  Show,
  SimpleShowLayout,
  useDataProvider,
  useNotify,
  TopToolbar,
  ListButton,
  EditButton,
  CloneButton
} from 'react-admin';
import { useMediaQuery, Button, Box, Typography } from '@material-ui/core';
import NameField from '../../components/NameField';

const asideStyles = {
  width: 300,
  backgroundColor: 'white',
  marginLeft: '1em',
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  borderRadius: '4px',
  padding: '16px',
  height: '200px'
}

const asideMobileStyles = {
  backgroundColor: '#f2f9ff',
  padding: '16px',
}

const MessageActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

const Aside = ({ record, isMobile = false }) => {
  if (!record) return null;
  return (
    <Box style={isMobile ? asideMobileStyles : asideStyles}>
      <Typography variant="subtitle1">Veröffentlichen</Typography>
      {record && (
        <>
          <SimpleShowLayout record={record}>
            <DateField source="created_at" label="Erstellt" />
            <DateField source="updated_at" label="Aktualisiert" />
          </SimpleShowLayout>
          <Box my={1}>
            <PublishButton record={record} />
          </Box>
        </>
      )}
    </Box>
  )
}

const PublishButton = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleClick = () => {
    dataProvider.update('messages', { id: record.id, data: { published: !record.published } })
      .then((response) => {
        notify(response.data.published ? 'Die Meldung wurde veröffentlicht' : 'Die Meldung wurde verborgen');
      })
      .catch((e) => {
        notify('Es ist ein Fehler aufgetretten', 'warning')
      });
  }

  return (
    <Button variant="contained" color={record.published ? 'default' : 'primary'} onClick={handleClick}>
      {record.published ? 'Verbergen' : 'Veröffentlichen'}
    </Button>
  )
};

const MessageTitle = ({ record }) => <span>{record ? record.subject : 'Meldung'}</span>

export const MessageShow = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Show {...props} aside={isSmall ? null : <Aside />} title={<MessageTitle />} actions={<MessageActions />}>
      <SimpleShowLayout>
        <NameField source="author" label="Autor" />
        <TextField source="subject" label="Betreff" />
        <TextField source="Body" label="Text" />
        <DateField source="created_at" label="Erstellt am" />
        <ImageField source="teaser.fullUrl" title="teaser.name" label="Teaser Bild" />
        <ArrayField source="attachment" label="Attachment">
          <Datagrid>
            <FileField source="fullUrl" title="name" target="_blank" label="Datei" sortable={false} />
          </Datagrid>
        </ArrayField>
        <ArrayField source="groups" label="Gruppen">
          <SingleFieldList><ChipField source="DisplayName" /></SingleFieldList>
        </ArrayField>
        {isSmall && <Aside record={props.record} isMobile={true} />}
      </SimpleShowLayout>
    </Show>
  )
};