import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  FileField,
  FileInput,
  SelectArrayInput,
  BooleanField,
  TopToolbar,
  ListButton,
  ShowButton,
  CloneButton,
  BooleanInput
} from 'react-admin';
import useGroups from '../resourceHooks/useGroups';
import { useInputStyles } from '../../styles/index';
import NameField from '../../components/NameField';

const MessageTitle = ({ record }) => <span>{record ? record.subject : 'Meldung'}</span>

const MessageActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const MessageEdit = props => {
  const groups = useGroups();
  const classes = useInputStyles();

  return (
    <Edit {...props} title={<MessageTitle />} actions={<MessageActions />}>
      <SimpleForm redirect="show">
        <NameField source="author" label="Autor" />
        <TextInput source="subject" label="Betreff" className={classes.input} />
        <TextInput source="Body" label="Text" multiline={true} className={classes.textarea} />
        <FileInput source="attachment" label="Attachment" className={classes.droparea} multiple={true}>
          <FileField source="fullUrl" title="name" target="_blank" />
        </FileInput>
        <FileInput source="teaser" label="Teaser Bild" className={classes.droparea}>
          <FileField source="fullUrl" title="name" target="_blank" />
        </FileInput>
        <SelectArrayInput label="Gruppen" source="groupsIds" choices={groups} optionText="DisplayName" className={classes.list} />
        <BooleanField source="published" label="Veröffentlicht" />
        <BooleanInput source="updateNotify" label="Benachrichtigung über Änderungen senden" className={classes.update} />
      </SimpleForm>
    </Edit>
  )
}