import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  FileField,
  FileInput,
  BooleanInput,
  SelectArrayInput,
  AutocompleteArrayInput,
  TopToolbar,
  ListButton,
  ShowButton,
  CloneButton
} from 'react-admin';
import useGroups from '../resourceHooks/useGroups';
import useUsers from '../resourceHooks/useUsers';
import { useInputStyles } from '../../styles/index';

const DocumentActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

const DocumentTitle = ({ record }) => <span>{record ? record.name : 'Dokument'}</span>

const optionRenderer = choice => choice ? `${choice.Firstname} ${choice.Lastname}` : '';

export const DocumentEdit = props => {
  const groups = useGroups();
  const users = useUsers();
  const classes = useInputStyles();

  return (
    <Edit {...props} title={<DocumentTitle />} actions={<DocumentActions />}>
      <SimpleForm redirect="show">
        <TextInput source="name" label="Name" className={classes.input} />
        <TextInput source="category" label="Kategorie" className={classes.input_md} />
        <FileInput source="file" label="Datei" className={classes.droparea}>
          <FileField source="fullUrl" title="name" target="_blank" />
        </FileInput>
        <SelectArrayInput label="Gruppen" source="groupsIds" choices={groups} optionText="DisplayName" className={classes.list} />
        <AutocompleteArrayInput source="usersIds" label="Users" choices={users} optionText={optionRenderer} className={classes.input} />
        <BooleanInput source="updateNotify" label="Benachrichtigung über Änderungen senden" className={classes.update} />
      </SimpleForm>
    </Edit>
  )
}