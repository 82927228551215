import React from 'react';
import {
  SimpleForm,
  TextInput,
  FileField,
  FileInput,
  Create,
  SelectArrayInput,
  TopToolbar,
  ListButton
} from 'react-admin';
import useGroups from '../resourceHooks/useGroups';
import { useInputStyles } from '../../styles/index';

const MessageActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const MessageCreate = props => {
  const groups = useGroups();
  const classes = useInputStyles();

  return (
    <Create {...props} title="Neue Meldung" actions={<MessageActions />}>
      <SimpleForm redirect="show">
        <TextInput source="subject" label="Betreff" className={classes.input} />
        <TextInput source="Body" label="Text" multiline={true} className={classes.textarea} />
        <FileInput source="attachment" label="Attachment" className={classes.droparea} multiple={true}>
          <FileField source="url" title="name" target="_blank" />
        </FileInput>
        <FileInput source="teaser" label="Teaser Bild" className={classes.droparea}>
          <FileField source="url" title="name" target="_blank" />
        </FileInput>
        <SelectArrayInput label="Gruppen" source="groupsIds" choices={groups} optionText="DisplayName" className={classes.list} />
      </SimpleForm>
    </Create>
  )
}