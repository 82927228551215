import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDataProvider, DateField } from 'react-admin';
import {
  Card,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { useDashboardStyles } from '../../../styles/DashboardStyles';
import CardIcon from './CardIcon';
import VacationIcon from '@material-ui/icons/EventBusy';
import { holidayType } from '../../../resources/Vacations/data';

const VacationInfo = ({ record }) => (
  <>
    <span>{holidayType[record.type]}</span>, von <DateField source="start" record={record} /> bis <DateField source="finish" record={record} />
  </>
)

const NewVacations = () => {
  const [vacations, setVacations] = useState([]);
  const dataProvider = useDataProvider();
  const classes = useDashboardStyles();

  const fetchVacations = useCallback(async () => {
    const response = await dataProvider.getList('vacations', {
      filter: { approved_null: true },
      sort: { field: 'created_at', order: 'DESC' },
      pagination: { page: 1, perPage: 100 }
    });
    if (!!response && response.data) setVacations(response.data);

  }, [dataProvider])

  useEffect(() => {
    fetchVacations();
  }, [fetchVacations]);

  return (
    <div className={classes.main}>
      <CardIcon Icon={VacationIcon} bgColor="#ff9800" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          Neue Urlaubsanträge
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          className={classes.value}
        >
          <Link to='/vacations' className={classes.titleLink}>
            {vacations.length}
          </Link>
        </Typography>
        <Divider />
        {vacations && (
          <List>
            {vacations.map((record) => (
              <ListItem
                key={record.id}
                button
                component={Link}
                to={`/vacations/${record.id}/show`}
                alignItems="flex-start"
              >
                <ListItemText
                  primary={record.name ? record.name : record.user.username}
                  secondary={<VacationInfo record={record} />}
                  className={classes.listItemText}
                  style={{ paddingRight: 0 }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Card>
    </div>
  )
};

export default NewVacations;