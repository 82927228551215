import React from 'react';
import {
  TextField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  EditButton,
  CloneButton
} from 'react-admin';
import { useMediaQuery, Box, Typography } from '@material-ui/core';
import SetNewPassword from './SetNewPassword';
import SetNewPIN from './SetNewPIN';

const UserActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

const asideStyles = {
  width: 300,
  backgroundColor: 'white',
  marginLeft: '1em',
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  borderRadius: '4px',
  padding: '16px',
  height: '200px'
}

const asideMobileStyles = {
  backgroundColor: '#f2f9ff',
  padding: '16px',
}

const Aside = ({ record, isMobile = false }) => {
  if (!record) return null;
  return (
    <Box style={isMobile ? asideMobileStyles : asideStyles}>
      <Typography variant="subtitle1">Account</Typography>
      {record && (
        <>
          <Box my={1}>
            <SetNewPassword record={record} />
          </Box>
          <Box my={1}>
            <SetNewPIN record={record} />
          </Box>
        </>
      )}
    </Box>
  )
}

const NameField = ({ record = {} }) => (
  record.Firstname
    ? `${record.Firstname} ${record.Lastname}`
    : record.username
      ? record.username
      : record.email
)

export const UserShow = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Show {...props} aside={isSmall ? null : <Aside />} title={<NameField />} actions={<UserActions />}>
      <SimpleShowLayout>
        <TextField source="username" label="Nutzername" />
        <TextField source="Firstname" label="Vorname" />
        <TextField source="Lastname" label="Nachname" />
        <TextField source="email" label="E-Mail" />
        <ArrayField source="groups" label="Gruppen">
          <SingleFieldList><ChipField source="DisplayName" /></SingleFieldList>
        </ArrayField>
        {/* <BooleanField source="confirmed" label="Bestätigt" /> */}
        <BooleanField source="blocked" label="Blockiert" />
        {isSmall && <Aside record={props.record} isMobile={true} />}
      </SimpleShowLayout>
    </Show>
  )
};