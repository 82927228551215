import React from 'react';
import {
  DateField,
  Show,
  SimpleShowLayout,
  NumberField,
  FileField,
  useDataProvider,
  useNotify,
  TopToolbar,
  ListButton,
  EditButton,
  CloneButton
} from 'react-admin';
import { holidayType } from './data';
import CustomTextField from '../../components/CustomTextField';
import NameField from '../../components/NameField';
import { useMediaQuery, Button, Box, Typography } from '@material-ui/core';

const VacationActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

const TypeField = ({ record = {}, label }) => (
  <CustomTextField label={label}>
    {holidayType[record.type]}
  </CustomTextField>
)

const asideStyles = {
  width: 300,
  backgroundColor: 'white',
  marginLeft: '1em',
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  borderRadius: '4px',
  padding: '16px',
  height: '200px'
}

const asideMobileStyles = {
  backgroundColor: '#f2f9ff',
  padding: '16px',
}

const actionStyles = {
  margin: '0 10px'
}

const VacationAsideActions = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleAcceptClick = () => {
    dataProvider.update('vacations', { id: record.id, data: { approved: true } })
      .then((response) => {
        notify('Der Urlaubantrag wurde akzeptiert');
      })
      .catch((e) => {
        notify('Es ist ein Fehler aufgetretten', 'warning')
      });
  }

  const handleDeclineClick = () => {
    dataProvider.update('vacations', { id: record.id, data: { approved: false } })
      .then((response) => {
        notify('Der Urlaubantrag wurde abgelehnt');
      })
      .catch((e) => {
        notify('Es ist ein Fehler aufgetretten', 'warning')
      });
  }

  if (record.approved !== null) return (
    <Button variant="contained" color='default' disabled={true}>
      {record.approved ? 'Akzeptiert' : 'Abgelehnt'}
    </Button>
  )
  return (
    <>
      <Button style={actionStyles} variant="contained" color='primary' onClick={handleAcceptClick}>Akzeptieren</Button>
      <Button style={actionStyles} variant="contained" color='secondary' onClick={handleDeclineClick}>Ablehnen</Button>
    </>
  )
};

const Aside = ({ record, isMobile = false }) => {
  if (!record) return null;
  return (
    <Box style={isMobile ? asideMobileStyles : asideStyles}>
      <Typography variant="subtitle1">Akzeptieren</Typography>
      {record && (
        <>
          <SimpleShowLayout record={record}>
            <DateField source="created_at" label="Erstellt" />
          </SimpleShowLayout>
          <Box my={1}>
            <VacationAsideActions record={record} />
          </Box>
        </>
      )}
    </Box>
  )
}

const Title = ({ record }) => <span>{record ? record.name ? record.name : `${record.user.Firstname} ${record.user.Lastname}` : 'Urlaubsantrag ' + record.id}</span>

export const VacationShow = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <Show {...props} title={<Title />} aside={isSmall ? null : < Aside />} actions={<VacationActions />}>
      <SimpleShowLayout>
        <NameField source="user" label="Benutzer" />
        <TypeField source="type" label="Art der Abwesenheit" />
        <DateField source="start" label="Von" />
        <DateField source="finish" label="Bis" />
        <NumberField source="count" label="Anzahl der Urlaubstage" />
        <FileField source="attachment.fullUrl" title="attachment.name" target="_blank" label="Attachment" />
        {isSmall && <Aside record={props.record} isMobile={true} />}
      </SimpleShowLayout>
    </Show>
  )
};