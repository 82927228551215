import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  BooleanInput,
  SelectArrayInput,
  TopToolbar,
  ListButton,
  ShowButton,
  CloneButton
} from 'react-admin';
import useGroups from '../resourceHooks/useGroups';
import { useInputStyles } from '../../styles/index';

const UserActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

const NameField = ({ record = {} }) => (
  record.Firstname
    ? `${record.Firstname} ${record.Lastname}`
    : record.username
      ? record.username
      : record.email
)

export const UserEdit = props => {
  const groups = useGroups();
  const classes = useInputStyles();

  return (
    <Edit {...props} title={<NameField />} actions={<UserActions />}>
      <SimpleForm redirect="show">
        <TextField source="username" label="Nutzername" />
        <TextInput source="email" label="E-Mail" className={classes.input_md} />
        <TextInput source="Firstname" label="Vorname" className={classes.input_md} />
        <TextInput source="Lastname" label="Nachname" className={classes.input_md} />
        <SelectArrayInput label="Gruppen" source="groupsIds" choices={groups} optionText="DisplayName" className={classes.list} />
        <BooleanInput source="blocked" label="Blockiert" />
      </SimpleForm>
    </Edit>
  )
}