import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  field: {
    padding: '8px 0 4px'
  } 
})

const CustomTextField = ({ label, children }) => {
  const classes = useStyles();

  return (
    <div className="MuiFormControl-root MuiFormControl-marginDense">
      <label className={`${classes.label} MuiFormLabel-root MuiInputLabel-shrink`}>
        <span>{label}</span>
      </label>
      <Typography variant="body2" className={classes.field}>
        {children}
      </Typography>
    </div>
  )
};

export default CustomTextField;