import React from 'react';
import { Field, Form } from 'react-final-form';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import { useLogin, useSafeSetState } from 'react-admin';
import Input from './Input';
import { useFormStyles } from '../../styles/FormStyles';

const LoginForm = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const [submitError, setSubmitError] = useSafeSetState('');
  const login = useLogin();
  const classes = useFormStyles();

  const validate = (values) => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = 'Username is required';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }
    return errors;
  };

  const submit = async (values) => {
    setLoading(true);
    await login(values)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (!!error.message && error.message.length > 0) {
          if (error.message[0].messages && error.message[0].messages.length > 0) {
            setSubmitError(error.message[0].messages[0].message);
          } else {
            setSubmitError(error.error);
          }
        } else {
          setSubmitError(error.error);
        }
      });
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.formWrapper}>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                label="Username"
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label="Passwort"
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
            </div>
            {submitError && (
              <Typography className={classes.formError}>
                <span className={classes.errorIcon}>!</span>
                {submitError}
              </Typography>
            )}
          </div>
          <Typography className={classes.submit}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              Einloggen
            </Button>
          </Typography>
        </form>
      )}
    />
  );
};

export default LoginForm;