import React from 'react';
import { Admin, Resource } from 'react-admin';
// providers
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
// resources
import { MessageList, MessageCreate, MessageEdit, MessageShow } from './resources/Messages/index';
import { DocumentList, DocumentCreate, DocumentEdit, DocumentShow } from './resources/Documents/index';
import { EventList, EventCreate, EventEdit, EventShow } from './resources/Events/index';
import { UserList, UserCreate, UserEdit, UserShow } from './resources/Users/index';
import { GroupList, GroupCreate, GroupEdit, GroupShow } from './resources/Groups/index';
import { VacationList, VacationCreate, VacationEdit, VacationShow } from './resources/Vacations/index';
// routes
import customRoutes from './routes/customRoutes';
// pages
import LoginPage from './pages/Login/LoginPage';
import Dashboard from './pages/Dashboard/Dashboard';
// icons
import Group from '@material-ui/icons/Group';
import Person from '@material-ui/icons/Person';
import Email from '@material-ui/icons/Email';
import Event from '@material-ui/icons/Event';
import EventBusy from '@material-ui/icons/EventBusy';
import Docs from '@material-ui/icons/FileCopy';
// theme
import { theme } from './styles/index';


const App = () => (
  <Admin
    customRoutes={customRoutes}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={LoginPage}
    dashboard={Dashboard}
    title="Diakonie Chat Admin"
    theme={theme}
  >
    <Resource
      name="messages"
      list={MessageList}
      edit={MessageEdit}
      create={MessageCreate}
      show={MessageShow}
      icon={Email}
      options={{ label: 'Meldungen' }}
    />
    <Resource
      name="documents"
      list={DocumentList}
      edit={DocumentEdit}
      create={DocumentCreate}
      show={DocumentShow}
      icon={Docs}
      options={{ label: 'Dokumente' }}
    />
    <Resource
      name="events"
      list={EventList}
      edit={EventEdit}
      create={EventCreate}
      show={EventShow}
      icon={Event}
      options={{ label: 'Veranstaltungen' }}
    />
    <Resource
      name="users"
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
      show={UserShow}
      icon={Person}
      options={{ label: 'Benutzer' }}
    />
    <Resource
      name="groups"
      list={GroupList}
      edit={GroupEdit}
      create={GroupCreate}
      show={GroupShow}
      icon={Group}
      options={{ label: 'Gruppen' }}
    />
    <Resource
      name="vacations"
      list={VacationList}
      edit={VacationEdit}
      create={VacationCreate}
      show={VacationShow}
      icon={EventBusy}
      options={{ label: 'Urlaubsanträge' }}
    />
  </Admin>
)

export default App;