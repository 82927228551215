import React from 'react';
import {
  SimpleForm,
  TextInput,
  DateTimeInput,
  FileField,
  FileInput,
  Create,
  SelectArrayInput,
  TopToolbar,
  ListButton
} from 'react-admin';
import useGroups from '../resourceHooks/useGroups';
import { useInputStyles } from '../../styles/index';

const EventActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const EventCreate = props => {
  const groups = useGroups();
  const classes = useInputStyles();

  return (
    <Create {...props} title="Neue Veranstaltung" actions={<EventActions />}>
      <SimpleForm redirect="show">
        <TextInput source="name" label="Name" className={classes.input} />
        <TextInput source="Description" label="Beschreibung" multiline={true} className={classes.textarea} />
        <DateTimeInput source="start" label="Start" className={classes.date} />
        <DateTimeInput source="finish" label="Ende" className={classes.date} />
        <FileInput source="teaser" label="Teaser Bild" className={classes.droparea}>
          <FileField source="fullUrl" title="name" target="_blank" />
        </FileInput>
        <SelectArrayInput label="Gruppen" source="groupsIds" choices={groups} optionText="DisplayName" className={classes.list} />
      </SimpleForm>
    </Create>
  )
}