import React from 'react';
import {
  Edit,
  SimpleForm,
  DateInput,
  FileField,
  FileInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  TopToolbar,
  ListButton,
  ShowButton,
  CloneButton
} from 'react-admin';
import { holidayTypeList } from './data';
import { useInputStyles } from '../../styles/index';
import NameField from '../../components/NameField';

const VacationActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
  </TopToolbar>
);

const Title = ({ record }) => <span>{record ? record.name ? record.name : `${record.user.Firstname} ${record.user.Lastname}` : 'Urlaubsantrag ' + record.id}</span>

export const VacationEdit = props => {
  const classes = useInputStyles();
  return (
    <Edit {...props} title={<Title />} actions={<VacationActions />}>
      <SimpleForm redirect="show">
        <NameField source="user" label="Benutzer" />
        <SelectInput source="type" choices={holidayTypeList} label="Art der Abwesenheit" className={classes.dropdown} />
        <DateInput source="start" label="Von" className={classes.date} />
        <DateInput source="finish" label="Bis" className={classes.date} />
        <NumberInput source="count" step={1} label="Anzahl der Urlaubstage" className={classes.number} />
        <FileInput source="attachment" label="Attachment" className={classes.droparea}>
          <FileField source="url" title="name" target="_blank" />
        </FileInput>
        <BooleanInput source="updateNotify" label="Benachrichtigung über Änderungen senden" className={classes.update} />
      </SimpleForm>
    </Edit>
  )
}