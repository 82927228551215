import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getAll('users')
      .then((response) => {
        if (response.data && response.data.length > 0) setUsers(response.data);  
      })
      .catch((e) => {
        console.error(e);
      })
  }, [ dataProvider ]);

  return users;
};

export default useUsers;