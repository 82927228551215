import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import CustomTextField from './CustomTextField';
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  link: {
    color: '#2196f3',
    fontSize: '1rem',
    textDecoration: 'none'
  }
})

export const FullNameField = ({ record = {} }) => {
  const classes = useStyles();

  return (
    <Typography variant="body2">
      <Link to={`/users/${record.id}/show`} className={classes.link}>
        {
          record.Firstname
            ? `${record.Firstname} ${record.Lastname}`
            : record.username
              ? record.username
              : record.email
        }
      </Link>
    </Typography>
  )
}

const NameField = ({ record = {}, label }) => (
  <CustomTextField label={label}>
    {record.name ? (
      <span>{record.name}</span>
    ) : (
        <FullNameField record={record.author ? record.author : record.user} />
      )}
  </CustomTextField>
)

export default NameField;