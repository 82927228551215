import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  CloneButton,
  TextInput,
  SelectArrayInput,
  SimpleList,
  Filter
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import useGroups from '../resourceHooks/useGroups';
import { useListStyles, useDatagridStyles, useInputStyles } from '../../styles/index';

const MessageFilter = (props) => {
  const groups = useGroups();
  const classes = useInputStyles();
  return (
    <Filter {...props}>
      <TextInput className={classes.input} label="Betreff" source="subject_contains" defaultValue="" alwaysOn />
      <TextInput className={classes.input} label="Text" source="Body_contains" defaultValue="" alwaysOn />
      <SelectArrayInput className={classes.list} label="Groups" source="groups_in" choices={groups} optionText="DisplayName" alwaysOn />
    </Filter>
  )
};

const BodyField = ({ record = {} }) => {
  let body = record.Body;
  if (record.Body.length > 100) {
    body = record.Body.substr(0, 96) + '...';
  };
  return body;
}

const NameField = ({ record = {} }) => (
  record.name
    ? record.name
    : record.author.Firstname
      ? `${record.author.Firstname} ${record.author.Lastname}`
      : record.author.username
        ? record.author.username
        : record.author.email
)

export const MessageList = (props) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const listClasses = useListStyles();
  const datagridClasses = useDatagridStyles();
  return (
    <List
      {...props}
      title="Meldungen"
      exporter={false}
      bulkActionButtons={false}
      filters={<MessageFilter />}
      classes={{ ...listClasses }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => record.subject}
          secondaryText={record => record.Body}
          tertiaryText={record => <DateField source="created_at" record={record} />}
          linkType="show"
        />
      ) : (
          <Datagrid rowClick="show" classes={{ ...datagridClasses }}>
            <NameField source="author" label="Autor" />
            <TextField source="subject" label="Betreff" />
            <BodyField source="Body" label="Text" />
            <DateField source="created_at" label="Erstellt am" />
            <ArrayField source="groups" sortable={false}>
              <SingleFieldList><ChipField source="DisplayName" key="id" /></SingleFieldList>
            </ArrayField>
            <BooleanField source="published" label="Veröffentlicht" />
            <EditButton />
            <CloneButton />
          </Datagrid>
        )}
    </List>
  )
}