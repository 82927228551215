import { makeStyles } from '@material-ui/core';

export const useDashboardStyles = makeStyles(theme => ({
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  titleLink: { textDecoration: 'none', color: 'inherit' },
  card: {
    padding: '16px 0',
    overflow: 'inherit',
    textAlign: 'right',
  },
  title: {
    padding: '0 16px',
  },
  value: {
    padding: '0 16px',
    minHeight: 48,
  },
  avatar: {
    background: theme.palette.background.paper,
  },
  listItemText: {
    overflowY: 'hidden',
    height: '4em',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
}));