import { makeStyles } from '@material-ui/core';

export const useDatagridStyles = makeStyles({
  table: {
    
  },
  tbody: {
    
  },
  headerCell: {
    backgroundColor: 'rgba(120, 144, 156, 0.2)'
  },
  row: {
    
  },
  rowEven: {

  },
  rowOdd: {

  },
  rowCell: {

  }
});