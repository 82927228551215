import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  Filter,
  TextInput,
  SelectArrayInput,
  SimpleList,
  CloneButton
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import useGroups from '../resourceHooks/useGroups';
import { useListStyles, useDatagridStyles, useInputStyles } from '../../styles/index';

const EventFilter = (props) => {
  const groups = useGroups();
  const classes = useInputStyles();
  return (
    <Filter {...props}>
      <TextInput className={classes.input} label="Name" source="name_contains" defaultValue="" alwaysOn />
      <TextInput className={classes.input} label="Beschreibung" source="Description_contains" defaultValue="" alwaysOn />
      <SelectArrayInput className={classes.list} label="Groups" source="groups_in" choices={groups} optionText="DisplayName" alwaysOn />
    </Filter>
  )
}

const DescriptionField = ({ record = {} }) => {
  let description = record.Description;
  if (record.Description && record.Description.length > 100) {
    description = record.Description.substr(0, 96) + '...';
  };
  return description;
}

export const EventList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const listClasses = useListStyles();
  const datagridClasses = useDatagridStyles();
  return (
    <List
      {...props}
      title="Veranstaltungen"
      exporter={false}
      bulkActionButtons={false}
      filters={<EventFilter />}
      classes={{ ...listClasses }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => record.Description}
          tertiaryText={record => (
            <>
              <DateField source="start" showTime record={record} />
              &nbsp;-&nbsp;
              <DateField source="finish" showTime record={record} />
            </>
          )}
          linkType="show"
        />
      ) : (
          <Datagrid rowClick="show" classes={{ ...datagridClasses }}>
            <TextField source="name" />
            <DescriptionField source="Description" label="Beschreibung" />
            <DateField source="start" showTime label="Start" />
            <DateField source="finish" showTime label="Ende" />
            <ArrayField source="groups">
              <SingleFieldList><ChipField source="DisplayName" /></SingleFieldList>
            </ArrayField>
            <EditButton />
            <CloneButton />
          </Datagrid>
        )}
    </List>
  )
};