import React, { useState } from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import { apiUrl, httpClient } from '../../providers/dataProvider';

function generatePIN(length = 4) {
  let pin = '';
  for (let i = 0; i < length; i++) {
    const character = Math.floor(Math.random() * 10);
    pin += character;
  }
  return pin;
}

const SetNewPIN = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(false);
  const [pin, setPin] = useState('');

  const handleClick = () => {
    setShowDialog(true);
  }

  const handleCloseClick = () => {
    setPin('');
    setShowDialog(false);
  }

  const handlePasswordChange = (event) => {
    setPin(event.target.value);
  }

  const handleGenerateClick = () => {
    const newPassword = generatePIN();
    setPin(newPassword);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false);
    const token = localStorage.getItem('jwt');
    return httpClient(`${apiUrl}/updatePin`, {
      headers: new Headers({
        Authorization: `Bearer ${token}`
      }),
      method: 'POST',
      body: JSON.stringify({
        id: record.id,
        pin: pin
      })
    }).then(({ json }) => {
      handleCloseClick();
    }).catch(error => setError('Das Passwort wurde nicht gespeichert'))
  };

  return (
    <>
      <Button variant="contained" color='primary' onClick={handleClick}>Neuen PIN</Button>
      <Dialog
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Neuen PIN erstellen"
      >
        <DialogTitle>Neuen PIN</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              id="pin"
              label="PIN"
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              value={pin}
              onChange={handlePasswordChange}
            />
            <Button color='default' type="button" onClick={handleGenerateClick}>Generieren</Button>
            {error && (
              <Typography color="error">
                {error}
              </Typography>
            )}

          </DialogContent>
          <DialogActions>
            <Button variant="contained" color='primary' type="submit">Speichern</Button>
            <Button label="ra.action.cancel" onClick={handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default SetNewPIN;