import React from 'react';
import {
  SimpleForm,
  DateInput,
  FileField,
  FileInput,
  Create,
  NumberInput,
  SelectInput,
  AutocompleteInput,
  TopToolbar,
  ListButton
} from 'react-admin';
import { holidayTypeList } from './data';
import { useInputStyles } from '../../styles/index';
import useUsers from '../resourceHooks/useUsers';

const VacationActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

const optionRenderer = choice => `${choice.Firstname} ${choice.Lastname}`;

export const VacationCreate = props => {
  const classes = useInputStyles();
  const users = useUsers();

  return (
    <Create {...props} title="Neues Urlaubsantrag" actions={<VacationActions />}>
      <SimpleForm redirect="show">
        <AutocompleteInput source="user" choices={users} optionText={optionRenderer} label="Benutzer" className={classes.dropdown} />
        <SelectInput source="type" choices={holidayTypeList} label="Art der Abwesenheit" className={classes.dropdown} />
        <DateInput source="start" label="Von" className={classes.date} />
        <DateInput source="finish" label="Bis" className={classes.date} />
        <NumberInput source="count" step={1} label="Anzahl der Urlaubstage" className={classes.number} />
        <FileInput source="attachment" label="Attachment" className={classes.droparea}>
          <FileField source="url" title="name" target="_blank" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}